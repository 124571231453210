import { useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "react-bootstrap";
import Button from "../../core/Button/Button";
import { toast } from "react-toastify";
import { ErrorNotification } from "../ErrorNotification/ErrorNotification";
import "./PaymentModal.scss";

// Initialize Stripe
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "pk_test_51PY522HbzdnihNWQNwo0dSqhEBB0NkndDEJShQFhNKYi1HEU3tJOZsbFuzvSzpAyT6rS95MpFob535wgAZzOpXDP00LO6egY7d"
);

const PaymentForm = ({
  amount,
  currency,
  clientSecret,
  onSuccess,
  onClose,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement)!,
          },
        }
      );

      if (error) {
        // ErrorNotification(error.message);
      } else if (paymentIntent.status === "succeeded") {
        toast.success("Payment successful!");
        onSuccess(paymentIntent);
      }
    } catch (err: any) {
      ErrorNotification(err.message);
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="payment-form">
        <div className="amount-display">
          Amount to Pay: {amount} {currency}
        </div>
        <div className="card-element-container">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
        <div className="button-container">
          <Button
            className="cancel-btn me-3"
            buttonText="Cancel"
            type="button"
            onClickHandler={onClose}
          />
          <Button
            className="pay-btn"
            buttonText={
              isProcessing ? "Processing..." : `Pay ${amount} ${currency}`
            }
            type="submit"
            isDisabled={isProcessing || !stripe}
          />
        </div>
      </div>
    </form>
  );
};

export const PaymentModal = ({
  show,
  onClose,
  amount,
  currency,
  clientSecret,
  onSuccess,
}: any) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Complete Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Elements stripe={stripePromise}>
          <PaymentForm
            amount={amount}
            currency={currency}
            clientSecret={clientSecret}
            onSuccess={onSuccess}
            onClose={onClose}
          />
        </Elements>
      </Modal.Body>
    </Modal>
  );
};
